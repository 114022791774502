<template>
  <div class="about-container">
    <div class="about-header glass-panel">
      <div class="header-content">
        <svg class="header-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path d="M12 2L2 7l10 5 10-5-10-5z"/>
          <path d="M2 17l10 5 10-5"/>
          <path d="M2 12l10 5 10-5"/>
        </svg>
        <h1 class="header-title">关于引文范式智能转译系统</h1>
      </div>
    </div>

    <div class="about-content">
      <section class="feature-section glass-panel">
        <div class="feature-header">
          <svg class="feature-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"/>
            <polyline points="22 4 12 14.01 9 11.01"/>
          </svg>
          <h2>核心功能</h2>
        </div>
        <div class="feature-list">
          <div class="feature-item">
            <svg class="item-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
            </svg>
            <p>快速转换多种文献格式</p>
          </div>
          <div class="feature-item">
            <svg class="item-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path d="M2 5h7"/>
                <path d="M2 10h7"/>
                <path d="M2 15h7"/>
                <path d="M2 20h7"/>
                <path d="M22 5h-7"/>
                <path d="M22 10h-7"/>
                <path d="M22 15h-7"/>
                <path d="M22 20h-7"/>
                <path d="M12 5v15"/>
              </svg>
            <p>支持多语言翻译</p>
          </div>
          <div class="feature-item">
            <svg class="item-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <rect x="9" y="9" width="13" height="13" rx="2" ry="2"/>
              <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>
            </svg>
            <p>一键复制转换结果</p>
          </div>
        </div>
      </section>

      <section class="version-section glass-panel">
        <div class="version-header">
          <svg class="version-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <circle cx="12" cy="12" r="10"/>
            <line x1="12" y1="16" x2="12" y2="12"/>
            <line x1="12" y1="8" x2="12.01" y2="8"/>
          </svg>
          <h2>版本信息</h2>
        </div>
        <div class="version-content">
          <p class="version-text">当前版本：v1.0.0</p>
          <p class="version-text">最后更新：2024-12-01</p>
          <p class="version-text">开发者：MistPeak</p>
        </div>
      </section>

      <section class="contact-section glass-panel">
        <div class="contact-header">
          <svg class="contact-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"/>
            <polyline points="22,6 12,13 2,6"/>
          </svg>
          <h2>联系我们</h2>
        </div>
        <div class="contact-content">
          <p class="contact-text">邮箱：aixiao@aixiao.xyz</p>
          <p class="contact-text">网站：www.aixiao.xyz</p>
          <p class="contact-text">地址：灵境</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style scoped>
.about-container {
  min-height: calc(100vh - var(--nav-height));
  padding: 2rem;
  background: #f8f9fa;
}

.about-header {
  margin-bottom: 2rem;
  padding: 2rem;
  text-align: center;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.header-icon {
  width: 48px;
  height: 48px;
  stroke: #FF7043;
}

.header-title {
  font-size: 2rem;
  color: #2D3748;
  margin: 0;
}

.about-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 2rem;
}

.glass-panel {
  background: rgba(255, 255, 255, 0.95);
  border: 1px solid rgba(255, 112, 67, 0.2);
  border-radius: 24px;
  box-shadow: 0 8px 32px rgba(255, 112, 67, 0.1);
  padding: 2rem;
}

/* 特性部分样式 */
.feature-section {
  text-align: center;
}

.feature-header, .version-header, .contact-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.feature-icon, .version-icon, .contact-icon {
  width: 32px;
  height: 32px;
  stroke: #FF7043;
}

.feature-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 112, 67, 0.05);
  border-radius: 16px;
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-4px);
}

.item-icon {
  width: 24px;
  height: 24px;
  stroke: #FF7043;
  flex-shrink: 0;
}

/* 版本信息和联系方式样式 */
.version-content, .contact-content {
  text-align: center;
}

.version-text, .contact-text {
  margin: 0.5rem 0;
  color: #4A5568;
  line-height: 1.6;
}

h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #2D3748;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .about-container {
    padding: 1rem;
  }

  .header-title {
    font-size: 1.5rem;
  }

  .feature-list {
    grid-template-columns: 1fr;
  }

  .glass-panel {
    padding: 1.5rem;
  }
}
</style>