<template>
  <div id="app">
    <!-- 背景动画效果 -->
    <div class="background-animation">
      <div class="gradient-circle circle-1"></div>
      <div class="gradient-circle circle-2"></div>
      <div class="gradient-circle circle-3"></div>
    </div>

    <!-- 导航栏 -->
    <nav class="nav-bar">
      <div class="nav-content">
        <router-link to="/" class="logo">
          <div class="logo-icon">
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6z"/>
    <polyline points="14 2 14 8 20 8"/>
    <path d="M7 13h10 M7 17h10 M7 9h2"/>
    <circle cx="18" cy="18" r="2"/>
    <path d="M10 18H4"/>
  </svg>
</div>
          <span class="logo-text">引文范式智能转译系统</span>
        </router-link>

        <div class="nav-links">
          <a @click="currentView = 'format'" 
              class="nav-link"
              :class="{ active: currentView === 'format' }">
              首页
            </a>
            <a @click="currentView = 'about'" 
              class="nav-link"
              :class="{ active: currentView === 'about' }">
              关于
            </a>
          <a href="https://github.com/yourusername/project" 
             target="_blank" 
             rel="noopener noreferrer" 
             class="nav-link github-link">
            <svg viewBox="0 0 24 24" class="github-icon">
              <path fill="currentColor" d="M12 2C6.477 2 2 6.477 2 12c0 4.42 2.865 8.17 6.839 9.49.5.092.682-.217.682-.482 0-.237-.008-.866-.013-1.7-2.782.604-3.369-1.34-3.369-1.34-.454-1.156-1.11-1.464-1.11-1.464-.908-.62.069-.608.069-.608 1.003.07 1.531 1.03 1.531 1.03.892 1.529 2.341 1.087 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.11-4.555-4.943 0-1.091.39-1.984 1.029-2.683-.103-.253-.446-1.27.098-2.647 0 0 .84-.269 2.75 1.025A9.578 9.578 0 0112 6.836c.85.004 1.705.114 2.504.336 1.909-1.294 2.747-1.025 2.747-1.025.546 1.377.203 2.394.1 2.647.64.699 1.028 1.592 1.028 2.683 0 3.842-2.339 4.687-4.566 4.935.359.309.678.919.678 1.852 0 1.336-.012 2.415-.012 2.743 0 .267.18.578.688.48C19.137 20.167 22 16.418 22 12c0-5.523-4.477-10-10-10z"/>
            </svg>
          </a>
        </div>
      </div>
    </nav>

    <!-- 主要内容区域 -->
    <main class="main-content">
      <transition name="fade" mode="out-in">
        <FormatConverter v-if="currentView === 'format'" />
        <About v-else-if="currentView === 'about'" />
      </transition>
    </main>

    <!-- 页脚 -->
    <footer class="footer">
      <div class="footer-content">
        <p class="copyright">© {{ currentYear }} Legal Reference Formatter. All rights reserved.</p>
        <div class="footer-links">
          <a href="#privacy" class="footer-link">隐私政策</a>
          <a href="#terms" class="footer-link">使用条款</a>
          <a href="#contact" class="footer-link">联系我们</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import FormatConverter from './components/FormatConverter.vue'
import About from './views/About.vue'

export default {
  name: 'App',
  components: {
    FormatConverter,
    About
  },
  data() {
    return {
      currentView: 'format'
    }
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style>
/* 重置样式 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary-color: #ff7043;
  --primary-light: #ffa270;
  --primary-dark: #c63f17;
  --secondary-color: #ffb74d;
  --background-start: #fff3e0;
  --background-end: #ffe0b2;
  --text-primary: #333;
  --text-secondary: #666;
  --nav-height: 64px;
  --footer-height: 60px;
}

/* 全局样式 */
body {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-start);
  color: var(--text-primary);
  line-height: 1.6;
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  isolation: isolate;
}

/* 背景动画 */
.background-animation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  overflow: hidden;
}

.gradient-circle {
  position: absolute;
  border-radius: 50%;
  filter: blur(40px);
  opacity: 0.6;
  animation: float 10s infinite ease-in-out;
}

.circle-1 {
  width: 300px;
  height: 300px;
  background: radial-gradient(circle, var(--primary-light), transparent 70%);
  top: -50px;
  right: -50px;
  animation-delay: 0s;
}

.circle-2 {
  width: 400px;
  height: 400px;
  background: radial-gradient(circle, var(--secondary-color), transparent 70%);
  bottom: -100px;
  left: -100px;
  animation-delay: -3s;
}

.circle-3 {
  width: 250px;
  height: 250px;
  background: radial-gradient(circle, var(--primary-color), transparent 70%);
  top: 40%;
  left: 60%;
  animation-delay: -6s;
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-20px) scale(1.05);
  }
}

/* 导航栏样式 */
.nav-bar {
  height: var(--nav-height);
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(255, 112, 67, 0.1);
  position: sticky;
  top: 0;
  z-index: 100;
}

.nav-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-link {
  cursor: pointer;
  color: var(--text-secondary);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.nav-link.active {
  color: var(--primary-color);
  background: rgba(255, 112, 67, 0.1);
}
.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: var(--primary-dark);
  font-weight: 600;
  font-size: 1.25rem;
}

.logo-icon {
  width: 32px;
  height: 32px;
  color: var(--primary-color);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.nav-link {
  color: var(--text-secondary);
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  padding: 0.5rem 1rem;
  border-radius: 8px;
}

.nav-link:hover {
  color: var(--primary-color);
  background: rgba(255, 112, 67, 0.1);
}

.github-icon {
  width: 24px;
  height: 24px;
}

/* 主要内容区域 */
.main-content {
  flex: 1;
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
    z-index: 1; 
}


/* 页脚样式 */
.footer {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 112, 67, 0.1);
  padding: 1rem 0;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copyright {
  color: var(--text-secondary);
  font-size: 0.9rem;
}

.footer-links {
  display: flex;
  gap: 1.5rem;
}

.footer-link {
  color: var(--text-secondary);
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: var(--primary-color);
}

/* 路由转换动画 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .nav-content,
  .footer-content {
    padding: 0 1rem;
  }

  .nav-links {
    gap: 1rem;
  }

  .main-content {
    padding: 1rem;
  }

  .footer-content {
    flex-direction: column;
    gap: 1rem;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .logo-text {
    display: none;
  }

  .nav-link span {
    display: none;
  }

  .github-icon {
    width: 20px;
    height: 20px;
  }
}
</style>
