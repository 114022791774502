<template>
  <div class="format-converter">
    <div class="converter-workspace">
      <!-- 左侧输入区域 -->
      <section class="input-section glass-panel">
        <div class="section-header">
          <div class="header-title">
            <svg class="header-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"/>
              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"/>
            </svg>
            <span>输入文献信息</span>
          </div>
          <button @click="toggleHelp" class="help-btn">
  <svg class="help-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
    <circle cx="12" cy="12" r="10"/>
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"/>
    <line x1="12" y1="17" x2="12.01" y2="17"/>
  </svg>
  <span class="help-text">使用说明</span>
</button>
        </div>
<div class="standard-selector">
  <label for="standard-select">引用标准：</label>
  <select id="standard-select" v-model="selectedStandard" class="standard-select">
    <option value="LEGAL">《法学引注手册》</option>
    <option value="GB">《GB/T 7714-2005》</option>
  </select>
</div>
        <div class="input-container">
          <textarea
            v-model="inputText"
            placeholder="在这里粘贴您的文献信息..."
            class="text-input"
            :class="{ 'has-content': inputText.length > 0 }"
          ></textarea>

          <div class="floating-tips" v-if="showFloatingTips">
            <div class="tip-bubble">
              <svg class="warning-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path d="M12 2L2 19H22L12 2Z" />
                <path d="M12 8V12" />
                <path d="M12 15H12.01" />
              </svg>
              <span>粘贴任意格式的文献信息，我们将自动为您转换格式</span>
            </div>
          </div>
        </div>

        <button 
          @click="handleConvert"
          class="convert-btn glow-effect"
          :class="{ 'loading': isConverting }"
          :disabled="!inputText.trim() || isConverting"
        >
          <svg v-if="!isConverting" class="btn-icon1" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M16 3h5v5"/>
            <path d="M4 20L21 3"/>
            <path d="M21 16v5h-5"/>
            <path d="M4 4l5 5"/>
          </svg>
          <svg v-else class="btn-icon1 rotating" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
            <path d="M21 12a9 9 0 11-9-9 9 9 0 019 9z"/>
            <path d="M12 3v3"/>
            <path d="M3 12h3"/>
            <path d="M12 21v-3"/>
            <path d="M21 12h-3"/>
          </svg>
          <span>{{ isConverting ? '正在转换...' : '开始转换' }}</span>
        </button>
      </section>

      <!-- 右侧结果区域 -->
      <section class="output-section" :class="{ 'show': hasResult }">
        <!-- 转换结果卡片 -->
        <div class="result-card glass-panel">
          <div class="section-header">
            <div class="header-title">
              <svg class="header-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path d="M22 11.08V12a10 10 0 11-5.93-9.14"/>
                <polyline points="22 4 12 14.01 9 11.01"/>
              </svg>
              <span>转换结果</span>
            </div>
            <div class="action-group">
              <button 
                @click="copyResult(result)" 
                class="action-btn copy-btn"
                :class="{ 'copied': isCopied.original }"
              >
                <svg class="btn-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <rect x="9" y="9" width="13" height="13" rx="2" ry="2"/>
                  <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>
                </svg>
                <span>{{ isCopied.original ? '已复制' : '复制' }}</span>
              </button>
              
              <button 
                @click="handleTranslate" 
                class="action-btn translate-btn"
                :disabled="isTranslating"
              >
                <svg class="btn-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M2 5h7"/>
                  <path d="M2 10h7"/>
                  <path d="M2 15h7"/>
                  <path d="M2 20h7"/>
                  <path d="M22 5h-7"/>
                  <path d="M22 10h-7"/>
                  <path d="M22 15h-7"/>
                  <path d="M22 20h-7"/>
                  <path d="M12 5v15"/>
                </svg>
                <span>{{ isTranslating ? '翻译中' : '翻译' }}</span>
              </button>
              <select v-model="targetLang" class="lang-select">
                <option value="EN">英语</option>
                <option value="ZH">中文</option>
                <option value="JA">日语</option>
                <option value="KO">韩语</option>
                <option value="FR">法语</option>
                <option value="DE">德语</option>
                <option value="ES">西班牙语</option>
                <option value="IT">意大利语</option>
                <option value="RU">俄语</option>
                <option value="PT">葡萄牙语</option>
              </select>
            </div>
          </div>

          <div class="result-content custom-scroll" v-if="result">
            <p class="result-text" v-for="(line, index) in resultLines" :key="index">{{ line }}</p>
          </div>
          
          <div class="empty-state" v-else>
            <svg class="empty-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
              <circle cx="12" cy="12" r="10"/>
              <line x1="12" y1="8" x2="12" y2="12"/>
              <line x1="12" y1="16" x2="12.01" y2="16"/>
            </svg>
            <span>转换结果将在这里显示</span>
          </div>
        </div>

        <!-- 翻译结果卡片 -->
        <div v-if="translatedResult" class="translation-card glass-panel slide-in">
          <div class="section-header">
            <div class="header-title">
              <svg class="header-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <path d="M2 5h7"/>
                <path d="M2 10h7"/>
                <path d="M2 15h7"/>
                <path d="M2 20h7"/>
                <path d="M22 5h-7"/>
                <path d="M22 10h-7"/>
                <path d="M22 15h-7"/>
                <path d="M22 20h-7"/>
                <path d="M12 5v15"/>
              </svg>
              <span>翻译结果</span>
            </div>
            <button 
              @click="copyResult(translatedResult)" 
              class="action-btn copy-btn"
              :class="{ 'copied': isCopied.translated }"
            >
              <svg class="btn-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <rect x="9" y="9" width="13" height="13" rx="2" ry="2"/>
                <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>
              </svg>
              <span>{{ isCopied.translated ? '已复制' : '复制' }}</span>
            </button>
          </div>
          <div class="result-content custom-scroll">
            <p class="result-text" v-for="(line, index) in translatedLines" :key="index">{{ line }}</p>
          </div>
        </div>

        <!-- 详细说明卡片 -->
        <div v-if="explanation" class="explanation-card glass-panel slide-in">
          <div class="section-header">
            <div class="header-title">
              <svg class="header-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <circle cx="12" cy="12" r="10"/>
                <path d="M12 8v4"/>
                <path d="M12 16h.01"/>
              </svg>
              <span>详细说明</span>
            </div>
          </div>
          <div class="explanation-content custom-scroll">
            <p class="explanation-text" v-for="(line, index) in explanationLines" :key="index">{{ line }}</p>
          </div>
        </div>
      </section>
    </div>

    <!-- 使用说明对话框 -->
    <transition name="fade">
      <div v-if="showHelp" class="help-overlay" @click.self="toggleHelp">
        <div class="help-dialog glass-panel slide-up">
          <div class="dialog-header">
            <h3>使用说明</h3>
            <button class="close-btn">
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </button>
          </div>
          <div class="help-content">
            <div class="help-step" v-for="(tip, index) in usageTips" :key="index">
              <div class="step-number">{{ index + 1 }}</div>
              <div class="step-content">
                <div class="step-icon" v-html="tipIcons[index]" />
                <p class="step-text">{{ tip }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { callOpenAI, translateText } from '../utils/apiService'

export default {
  name: 'FormatConverter',
  
  data() {
    return {
      selectedStandard: 'LEGAL',
      inputText: '',
      result: '',
      translatedResult: '',
      explanation: '',
      targetLang: 'EN',
      isConverting: false,
      isTranslating: false,
      showHelp: false,
      showFloatingTips: true,
      isCopied: {
        original: false,
        translated: false
      },
      usageTips: [
        '选择需要使用的引注标准',
        '粘贴任意格式的文献信息到输入框',
        '点击"开始转换"获取标准格式',
        '可以选择目标语言翻译结果',
        '使用复制按钮快速复制结果'
      ],
      tipIcons: [
       `<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"/>
        <polyline points="7 10 12 15 17 10"/>
        <line x1="12" y1="15" x2="12" y2="3"/>
      </svg>`,
        `<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"/>
          <path d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z"/>
        </svg>`,
        `<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path d="M16 3h5v5"/>
          <path d="M4 20L21 3"/>
          <path d="M21 16v5h-5"/>
          <path d="M4 4l5 5"/>
        </svg>`,
        `<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <path d="M2 5h7"/>
          <path d="M2 10h7"/>
          <path d="M2 15h7"/>
          <path d="M2 20h7"/>
          <path d="M22 5h-7"/>
          <path d="M22 10h-7"/>
          <path d="M22 15h-7"/>
          <path d="M22 20h-7"/>
          <path d="M12 5v15"/>
        </svg>`,
        `<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
          <rect x="9" y="9" width="13" height="13" rx="2" ry="2"/>
          <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"/>
        </svg>`
      ]
    }
  },

  computed: {
    hasResult() {
      //return !!(this.result || this.translatedResult || this.explanation)
      return true;
    },
    resultLines() {
      return this.result ? this.result.split('\n').filter(line => line.trim()) : []
    },
    translatedLines() {
      return this.translatedResult ? this.translatedResult.split('\n').filter(line => line.trim()) : []
    },
    explanationLines() {
      return this.explanation ? this.explanation.split('\n').filter(line => line.trim()) : []
    }
  },

  watch: {
    inputText() {
      if (this.inputText) {
        this.showFloatingTips = false
      }
    }
  },

  methods: {
    async handleConvert() {
      if (!this.inputText.trim()) return
      
      this.isConverting = true
      this.result = ''
      this.explanation = ''
      this.translatedResult = ''

      try {
        let fullResponse = ''
        await callOpenAI(
          this.inputText,
          this.selectedStandard,  // 传递选择的标准类型
          (chunk) => {
            fullResponse += chunk
          }
        )

        if (fullResponse.includes('===详细说明===')) {
          const [resultPart, explanationPart] = fullResponse.split('===详细说明===')
          this.result = resultPart.replace('===转换结果===', '').trim()
          this.explanation = explanationPart.trim()
        } else {
          this.result = fullResponse.trim()
        }
      } catch (err) {
        console.error('转换失败:', err)
      } finally {
        this.isConverting = false
      }
    },

    async handleTranslate() {
      if (this.isTranslating) return
      
      this.isTranslating = true
      try {
        const translated = await translateText(
          this.result,
          'ZH',
          this.targetLang
        )
        this.translatedResult = translated
      } catch (err) {
        console.error('翻译失败:', err)
      } finally {
        this.isTranslating = false
      }
    },

    async copyResult(text) {
      if (!text) return
      
      try {
        await navigator.clipboard.writeText(text)
        const key = text === this.result ? 'original' : 'translated'
        this.isCopied[key] = true
        setTimeout(() => {
          this.isCopied[key] = false
        }, 2000)
      } catch (err) {
        console.error('复制失败:', err)
      }
    },

    toggleHelp() {
      this.showHelp = !this.showHelp
    }
  }
}
</script>

<style scoped>
.format-converter {
 min-height: calc(100vh - var(--nav-height));
 padding: 2rem;
}

.converter-workspace {
 max-width: 1600px;
 margin: 0 auto;
 display: grid;
 grid-template-columns: minmax(300px, 2fr) 3fr;
 gap: 2rem;
 position: relative;
}

/* Glass Panel Styles */
.glass-panel {
 background: rgba(255, 255, 255, 0.95);
 border: 1px solid rgba(255, 112, 67, 0.2);
 border-radius: 24px;
 box-shadow: 0 8px 32px rgba(255, 112, 67, 0.1);
 padding: 1.5rem;
 transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
 position: relative;
}

.glass-panel::before {
 content: '';
 position: absolute;
 inset: 0;
 border-radius: 24px;
 padding: 1px;
 background: linear-gradient(135deg, rgba(255, 112, 67, 0.5), rgba(255, 183, 77, 0.5));
 -webkit-mask: 
   linear-gradient(#fff 0 0) content-box, 
   linear-gradient(#fff 0 0);
 mask: 
   linear-gradient(#fff 0 0) content-box, 
   linear-gradient(#fff 0 0);
 -webkit-mask-composite: xor;
 mask-composite: exclude;
}

.glass-panel:hover {
 transform: translateY(-2px);
 box-shadow: 0 12px 40px rgba(255, 112, 67, 0.15);
}

/* Input Section */  
.input-section {
 position: sticky;
 top: calc(var(--nav-height) + 2rem);
 height: fit-content;
}

.section-header {
 display: flex;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 1.5rem;
}

.header-title {
 display: flex;
 align-items: center;
 gap: 0.75rem;
 font-size: 1.25rem;
 font-weight: 600;
 color: #2D3748;
}

.header-icon {
 width: 28px;
 height: 28px;
 stroke: #FF7043;
}

.help-icon {
 width: 24px;
 height: 24px;
 stroke: #FF7043; 
}

.warning-icon {
 width: 20px;
 height: 20px;
 stroke: #FF7043;
}

.btn-icon {
 width: 20px;
 height: 20px;
 stroke: #FF7043;
 position: relative;
  z-index: 1;
  pointer-events: auto;
}
.btn-icon1 {
 width: 20px;
 height: 20px;
 position: relative;
  z-index: 1;
  pointer-events: auto;
}
/* Input Container */
.input-container {
 position: relative;
 margin-bottom: 1.5rem;
}

.text-input {
 width: 100%;
 min-height: 300px;
 padding: 1.25rem;
 border: 2px solid rgba(255, 112, 67, 0.2);
 border-radius: 16px;
 background: rgba(255, 255, 255, 0.8);
 font-size: 1rem;
 line-height: 1.6;
 color: #2D3748;
 resize: vertical;
 transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.text-input:focus {
 outline: none;
 border-color: #FF7043;
 box-shadow: 0 0 0 4px rgba(255, 112, 67, 0.15);
}

/* Convert Button Styles */
.convert-btn {
 width: 100%;
 padding: 1rem;
 background: linear-gradient(135deg, #FF7043, #FFB74D);
 border: none;
 border-radius: 16px;
 color: white;
 font-size: 1.1rem;
 font-weight: 600;
 display: flex;
 align-items: center;
 justify-content: center;
 gap: 0.75rem;
 cursor: pointer;
 transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
 box-shadow: 0 4px 15px rgba(255, 112, 67, 0.3);
 position: relative;
 overflow: hidden;
}

.convert-btn::before {
 content: '';
 position: absolute;
 top: 0;
 left: -100%;
 width: 100%;
 height: 100%;
 background: linear-gradient(
   90deg,
   transparent,
   rgba(255, 255, 255, 0.2),
   transparent
 );
 transition: 0.5s;
}

.convert-btn:hover:not(:disabled)::before {
 left: 100%;
}

.convert-btn:hover:not(:disabled) {
 transform: translateY(-2px);
 box-shadow: 0 6px 20px rgba(255, 112, 67, 0.4);
}

.convert-btn:disabled {
 opacity: 0.7;
 cursor: not-allowed;
}

/* Action Group */
.action-group {
 display: flex;
 align-items: center;
 gap: 1rem;
}

.language-selector {
 display: flex;
 align-items: center;
 gap: 0.75rem;
}

.lang-select {
 min-width: 80px;
 padding: 0.5rem 2rem 0.5rem 1rem;
 border: 2px solid rgba(255, 112, 67, 0.2);
 border-radius: 12px;
 background: rgba(255, 255, 255, 0.9);
 color: #FF7043;
 font-size: 0.9rem;
 font-weight: 500;
 text-align: center;
 text-align-last: center;
 appearance: none;
 background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23FF7043' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
 background-repeat: no-repeat;
 background-position: right 0.5rem center;
 background-size: 16px;
 cursor: pointer;
 transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
 position: relative;
  z-index: 1;
  pointer-events: auto;
}

.lang-select:focus {
 outline: none;
 border-color: #FF7043;
 box-shadow: 0 0 0 4px rgba(255, 112, 67, 0.15);
}

/* Action Buttons */
.action-btn {
 padding: 0.5rem 1rem;
 border: 2px solid rgba(255, 112, 67, 0.2);
 border-radius: 12px; 
 background: rgba(255, 255, 255, 0.9);
 color:#FF7043;
 font-size: 0.9rem;
 display: flex;
 align-items: center;
 gap: 0.5rem;
 cursor: pointer;
 transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
 position: relative;
  z-index: 1;
  pointer-events: auto;
}

.action-btn:hover:not(:disabled) {
 border-color: #FF7043;
 background: rgba(255, 112, 67, 0.1);
}

.action-btn:disabled {
 opacity: 0.7;
 cursor: not-allowed;
}

.action-btn.copied {
 background: #FF7043;
 border-color: #FF7043;
 color: white;
}

/* Result Content */
.result-content,
.explanation-content {
 padding: 1.25rem;
 background: rgba(255, 255, 255, 0.5);
 border-radius: 16px;
 border: 1px solid rgba(255, 112, 67, 0.1);
}

.result-text,
.explanation-text {
 margin: 0.5rem 0;
 font-size: 1rem;
 line-height: 1.8;
 color: #2D3748;
 white-space: pre-wrap;
 word-break: break-word;
}

/* Help Dialog */
.help-overlay {
 position: fixed;
 top: 0;
 left: 0;
 right: 0;
 bottom: 0;
 background: rgba(0, 0, 0, 0.5);
 backdrop-filter: blur(8px);
 display: flex;
 align-items: center;
 justify-content: center;
 z-index: 1000;
}

.help-dialog {
 width: 90%;
 max-width: 600px;
}

.dialog-header {
 display: flex;
 justify-content: space-between;
 align-items: center;
 padding: 1.5rem;
 border-bottom: 1px solid rgba(255, 112, 67, 0.2);
}

.dialog-header h3 {
 font-size: 1.5rem;
 font-weight: 600;
 color: #2D3748;
 margin: 0;
}

.help-content {
 padding: 1.5rem;
 display: grid;
 gap: 1.5rem;
}

.help-step {
 display: flex;
 align-items: center;
 gap: 1rem;
 padding: 1.5rem;
 background: rgba(255, 255, 255, 0.8);
 border-radius: 16px;
 border: 1px solid rgba(255, 112, 67, 0.2);
 transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.help-step:hover {
 transform: translateY(-2px);
 box-shadow: 0 8px 24px rgba(255, 112, 67, 0.15);
}

.step-number {
 width: 32px;
 height: 32px;
 background: #FF7043;
 color: white;
 border-radius: 50%;
 display: flex;
 align-items: center;
 justify-content: center;
 font-weight: 600;
 font-size: 1rem;
 flex-shrink: 0;
}

.step-content {
 flex: 1;
 display: flex;
 align-items: center;
 gap: 1rem;
}

.step-icon {
 width: 32px;
 height: 32px;
 flex-shrink: 0;
 color: #FF7043;
}

.step-text {
 margin: 0;
 color: #4A5568;
 font-size: 0.95rem;
 line-height: 1.6;
}

/* Empty State */
.empty-state {
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 1rem;
 padding: 3rem;
 color: #718096;
}

.empty-icon {
 width: 48px;
 height: 48px;
 stroke: #FF7043;
 opacity: 0.5;
}

/* Custom Scrollbar */
.custom-scroll::-webkit-scrollbar {
 width: 8px;
}

.custom-scroll::-webkit-scrollbar-track {
 background: rgba(255, 112, 67, 0.1);
 border-radius: 4px;
}

.custom-scroll::-webkit-scrollbar-thumb {
 background: #FF7043;
 border-radius: 4px;
}

/* Animations */
@keyframes rotate {
 from { transform: rotate(0deg); }
 to { transform: rotate(360deg); }
}

.rotating {
 animation: rotate 1s linear infinite;
}

.slide-in {
 animation: slideIn 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes slideIn {
 from {
   opacity: 0;
   transform: translateX(20px);
 }
 to {
   opacity: 1;
   transform: translateX(0);
 }
}

/* Floating Tips */
.floating-tips {
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -50%);
 pointer-events: none;
}

.tip-bubble {
 background: rgba(255, 112, 67, 0.1);
 border: 1px solid #FF7043;
 border-radius: 12px;
 padding: 1rem;
 display: flex;
 align-items: center;
 gap: 0.75rem;
 color: #FF7043;
 font-size: 0.9rem;
}

/* Transitions */
.fade-enter-active,
.fade-leave-active {
 transition: opacity 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter-from,
.fade-leave-to {
 opacity: 0;
 }

.slide-up-enter-active {
 transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-up-leave-active {
 transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.slide-up-enter-from,
.slide-up-leave-to {
 opacity: 0;
 transform: translateY(30px);
}

/* Output Section */
.output-section {
 display: flex;
 flex-direction: column;
 gap: 1.5rem;
 opacity: 0;
 transform: translateX(20px);
 transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
 pointer-events: auto;
}

.output-section.show {
 opacity: 1;
 transform: translateX(0);
}

/* Responsive Design */
@media (max-width: 1200px) {
 .converter-workspace {
   grid-template-columns: 1fr;
   gap: 1.5rem;
 }

 .input-section {
   position: relative;
   top: 0;
 }
}

@media (max-width: 768px) {
 .format-converter {
   padding: 1rem;
 }

 .section-header {
   flex-direction: column;
   align-items: flex-start;
   gap: 1rem;
 }

 .action-group {
   width: 100%;
   flex-wrap: wrap;
 }
 
 .language-selector {
   width: 100%;
   justify-content: space-between;
 }

 .help-step {
   flex-direction: column;
   align-items: flex-start;
 }
 
 .step-content {
   width: 100%;
 }
}

@media (max-width: 480px) {
 .glass-panel {
   padding: 1rem;
 }

 .header-title {
   font-size: 1.1rem;
 }

 .text-input {
   min-height: 200px;
 }
 
 .help-dialog {
   width: 95%;
 }
}
.help-btn {
  position: relative;
  z-index: 1;
  pointer-events: auto;
  background: transparent;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;  /* 图标和文字之间的间距 */
}

.help-btn:hover {
  transform: scale(1.1);
}

.help-icon {
  width: 24px;
  height: 24px;
  stroke: #FF7043;  /* 设置为橙色 */
}

.help-text {
  color: #FF7043;  /* 文字也设置为相同的橙色 */
  font-size: 0.9rem;
  font-weight: 500;
}
.standard-selector {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
   position: relative;
  z-index: 1;
  pointer-events: auto;
}

.standard-selector label {
  color: #2D3748;
  font-weight: 500;
}

.standard-select {
  flex: 1;
  padding: 0.75rem;
  border: 2px solid rgba(255, 112, 67, 0.2);
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.8);
  color: #2D3748;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.standard-select:focus {
  outline: none;
  border-color: #FF7043;
  box-shadow: 0 0 0 4px rgba(255, 112, 67, 0.15);
}
</style>