// src/utils/apiService.js
import { SYSTEM_PROMPT as LEGAL_SYSTEM_PROMPT, USER_PROMPT as LEGAL_USER_PROMPT } from './promptTemplates.js'
import { SYSTEM_PROMPT as GB_SYSTEM_PROMPT, USER_PROMPT as GB_USER_PROMPT } from './promptTemplates2.js'

export async function callOpenAI(inputText, standardType, streamCallback) {
  try {
    // 根据选择的标准类型选择对应的提示词
    const systemPrompt = standardType === 'GB' ? GB_SYSTEM_PROMPT : LEGAL_SYSTEM_PROMPT;
    const userPrompt = standardType === 'GB' ? GB_USER_PROMPT : LEGAL_USER_PROMPT;

    const response = await fetch(process.env.VUE_APP_OPENAI_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`
      },
      body: JSON.stringify({
        model: process.env.VUE_APP_MODEL,
        messages: [
          {
            role: "system",
            content: systemPrompt
          },
          {
            role: "user",
            content: userPrompt.replace('$INPUT_TEXT', inputText)
          }
        ],
        stream: true
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder();

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = decoder.decode(value);
      const lines = chunk.split('\n');
      
      for (const line of lines) {
        if (line.trim() === '' || line.includes('[DONE]')) continue;
        
        try {
          const parsed = JSON.parse(line.replace(/^data: /, ''));
          if (parsed.choices[0].delta.content) {
            streamCallback(parsed.choices[0].delta.content);
          }
        } catch (e) {
          console.error('Error parsing chunk:', e);
        }
      }
    }
  } catch (err) {
    console.error('API call failed:', err);
    throw err;
  }
}

export async function translateText(text, sourceLang, targetLang) {
  try {
    const response = await fetch(process.env.VUE_APP_DEEPL_API_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        text,
        source_lang: sourceLang,
        target_lang: targetLang
      })
    });

    const data = await response.json();
    return data.data;
  } catch (err) {
    console.error('Translation error:', err);
    throw new Error('翻译服务暂时不可用，请稍后重试');
  }
}